import {
  BranchItemFragment,
  OnlineOrderBranchConfigurationItemFragment,
  OpeningTimeServiceType,
} from '@app/graphql/types/graphql.ts';
import Card from '@app/components/card/card.tsx';
import CardContent from '@app/components/card/card-content.tsx';

import CDNLink from '@app/utils/cdn-link.ts';
import { useTranslate } from '@tolgee/react';
import LanguageSelectorAdvanced from '@app/components/language-selector.tsx';
import { todayIs } from '@app/page/online-order/product-picker/utils/today.ts';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';

export default function BusinessCard(props: {
  branch: BranchItemFragment;
  configuration?: OnlineOrderBranchConfigurationItemFragment | null;
}) {
  const { t } = useTranslate();

  const today = todayIs();
  const pickup = props.branch.openingTimes?.filter(
    (item) => item.dayOfWeek == today && item.service == OpeningTimeServiceType.OnlineOrderPickup
  );

  const delivery = props.branch.openingTimes?.filter(
    (item) => item.dayOfWeek == today && item.service == OpeningTimeServiceType.OnlineOrderDelivery
  );

  if (!props.configuration) {
    return <>Can not get online order branch configuration</>;
  }

  return (
    <>
      <Card className="z-10 shadow-md max-xl:rounded-none sm:relative sm:shadow-none">
        {props.branch.company.hubPage?.banner && (
          <div
            style={{
              backgroundImage: `url(${import.meta.env.VITE_CDN_HOST as string}/${props.branch.company.hubPage.banner ?? ''})`,
            }}
            className="hidden h-48 w-full bg-cover bg-center bg-no-repeat xl:block"
          ></div>
        )}
        <CardContent>
          <div className="flex">
            <div className="grow space-y-6">
              <div className="flex items-center gap-x-4">
                {props.branch.company.logo && (
                  <img
                    className="w-24 rounded-md"
                    src={CDNLink(props.branch.company.logo)}
                    alt={props.branch.company.name}
                  />
                )}
                <div>
                  <p className="pt-2 text-xl font-medium">{props.branch.company.name}</p>
                  <div className="block w-full pb-2 lg:hidden">
                    <LanguageSelectorAdvanced
                      customerDefaultLanguage={props.branch.company.settings.customerDefaultLanguage}
                    />
                  </div>
                  <p className="flex flex-wrap gap-x-1 text-sm text-gray-600">
                    <span>
                      {props.branch.streetAddress},
                    </span>
                    <span>
                      {props.branch.postalCode}
                    </span>
                    <span>
                      {props.branch.addressLocality}
                    </span>
                  </p>

                  <div className="grid grid-col-1 min-[560px]:grid-cols-2  items-center gap-2 mt-2.5">
                    {props.branch.contactPhone && (
                        <a href={`tel:${props.branch.contactPhone}`} className="text-sm  p-2 bg-indigo-500 text-white font-semibold rounded-md flex items-center gap-x-1 justify-center">
                          <PhoneIcon className="w-4 h-4"/>
                          <span>{props.branch.contactPhone}</span>
                        </a>
                    )}

                    {props.branch.contactEmail && (
                        <a href={`mailto:${props.branch.contactEmail}`} className="text-sm  p-2 bg-white text-gray-600 font-semibold rounded-md flex items-center gap-x-1 hover:bg-gray-100 border-2 justify-center">
                          <EnvelopeIcon className="w-4 h-4"/>
                          <span>{props.branch.contactEmail}</span>
                        </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="space-y-1 text-sm text-gray-500">
                {props.configuration.pickupEnable && pickup && pickup.length > 0 && (
                  <div className="flex items-center gap-x-1">
                    <span>{t('online-order.business-card.pickup', 'Today pickup')}:</span>
                    {pickup.map((item, index) => (
                      <p key={index}>
                        {item.openTime} - {item.closeTime}
                        {index < pickup.length - 1 && <span>,</span>}
                      </p>
                    ))}
                  </div>
                )}
                {props.configuration.deliveryEnable && delivery && delivery.length > 0 && (
                  <div className="flex items-center gap-x-1">
                    <span>{t('online-order.business-card.delivery', 'Today delivery')}:</span>
                    {delivery.map((item, index) => (
                      <p key={index}>
                        {item.openTime} - {item.closeTime}
                        {index < delivery.length - 1 && <span>,</span>}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="hidden w-40 lg:block">
              <LanguageSelectorAdvanced
                customerDefaultLanguage={props.branch.company.settings.customerDefaultLanguage}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
