import { graphql } from '@app/graphql/types';

export const OnlineOrderAvailabilityFragment = graphql(`
  fragment OnlineOrderAvailabilityItem on OnlineOrderAvailability {
    pickup {
      now
      orderForLater {
        startTime
        spotsOpen
        spotsTotal
      }
    }
    delivery {
      now
      orderForLater {
        startTime
        spotsOpen
        spotsTotal
      }
    }
  }
`);
