import { ReactNode } from 'react';
import classNames from 'classnames';

/**
 * Card component that contains different UI elements
 */
export default function Card(props: { className?: string; children?: ReactNode }) {
  return (
    <div
      className={classNames(
        'bg-clip divide-y divide-gray-200 overflow-visible rounded-xl bg-white bg-clip-content shadow',
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
