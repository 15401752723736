import { ReactNode, useEffect, useRef, useState } from 'react';
import DialogButton from '@app/components/dialog-button.tsx';

export default function HoverText(props: { text: ReactNode; hoverContent: ReactNode; modalContent: ReactNode }) {
  const { text, hoverContent, modalContent } = props;
  const [isHovering, setIsHovering] = useState(false);

  const [position, setPosition] = useState<'top' | 'bottom'>('bottom');
  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const updatePosition = () => {
      if (textRef.current) {
        const rect = textRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        setPosition(rect.top < viewportHeight / 2 ? 'bottom' : 'top');
      }
    };

    updatePosition();
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, []);

  return (
    <div className="relative inline-block overflow-visible">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogButton content={<div>{modalContent}</div>}>
          <span
            ref={textRef}
            className="cursor-pointer"
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
            onClick={() => {
              setIsHovering(false);
            }}
          >
            {text}
          </span>
        </DialogButton>
      </div>

      {isHovering && (
        <div
          className={`absolute left-0 z-40 min-w-32 rounded border border-gray-200 bg-white shadow-lg ${position === 'bottom' ? 'top-full mt-2' : 'bottom-full mb-2'} `}
        >
          {hoverContent}
        </div>
      )}
    </div>
  );
}
