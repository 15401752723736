import { Fragment, ReactNode } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';

export default function MenuSelectionInput<T>(props: {
  title: string;
  data: readonly T[];
  value: T | null;
  onChange: (newValue: T) => void;
  build: (item: T) => {
    id: string;
    name: string;
    suffix?: string;
    country?: string;
  };
  trailing?: ReactNode;
  className?: string;
  showFlag?: boolean;
}) {
  const { t } = useTranslate();

  const renderFlag = (item: T, size: 'small' | 'large' = 'small') => {
    const country = props.build(item).country;
    if (props.showFlag && country) {
      return (
        <img
          src={`https://flagcdn.com/${size === 'small' ? '16x12' : '32x24'}/${country}.png`}
          width={size === 'small' ? 20 : 32}
          height={size === 'small' ? 16 : 24}
          alt={country}
          className="mr-2 inline-block"
        />
      );
    }
    return null;
  };

  return (
    <Listbox value={props.value} onChange={props.onChange}>
      {({ open }) => (
        <div className={props.className}>
          <Listbox.Label className="mb-2 block text-sm font-medium leading-6 text-gray-900">
            {props.title}
          </Listbox.Label>
          <div className="relative">
            <div className="flex items-center gap-x-2">
              <Listbox.Button className="relative flex w-full cursor-default items-center rounded-md bg-white px-4 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <div className="flex w-full items-center gap-x-2">
                  {props.value && props.showFlag && renderFlag(props.value)}
                  <span className="block flex-grow truncate">
                    {props.value
                      ? props.build(props.value).name
                      : t('menu.menu-detail.product.create.form-input.menu-category.not-selected', 'Not selected')}
                  </span>
                </div>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center gap-1 pr-2">
                  {props.value && props.build(props.value).suffix && <span>{props.build(props.value).suffix}</span>}
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </Listbox.Button>

              {props.trailing}
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.data.map((item) => (
                  <Listbox.Option
                    key={props.build(item).id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative flex cursor-default select-none items-center px-4 py-2 pl-3 pr-9'
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex w-full items-center gap-x-2">
                          {props.showFlag && renderFlag(item)}
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {props.build(item).name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
