import { graphql } from '@app/graphql/types';

export const MenuFragment = graphql(`
  fragment MenuItem on Storefront_Menu {
    id
    title
    menuProducts {
      ...MenuProductItem
    }
    sections {
      ...MenuSectionItem
    }
  }
`);

export const MenuProductFragment = graphql(`
  fragment MenuProductItem on Storefront_MenuProduct {
    id
    title
    code
    description
    images
    enabled
    ingredients
    section {
      ...MenuSectionItem
    }
    configurations {
      ...MenuProductConfigurationItem
    }

    allergics {
      code
      title
    }
    menuId
    sectionId

    labels
  }
`);

export const MenuProductConfigurationFragment = graphql(`
  fragment MenuProductConfigurationItem on MenuProductConfiguration {
    id
    title
    type
    values {
      ...MenuProductConfigurationValueItem
    }
  }
`);

export const MenuProductConfigurationValueFragment = graphql(`
  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {
    id
    name
    price
  }
`);
