import { CloseButton, Description, DialogTitle, useClose } from '@headlessui/react';
import { MenuProductItemFragment, ProductConfigurationType } from '@app/graphql/types/graphql.ts';
import CDNLink from '@app/utils/cdn-link.ts';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import { useEffect, useState } from 'react';
import PrimaryButton from '@app/components/primary-button.tsx';
import CurrencyView from '@app/components/price/currency-view.tsx';
import { useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { calculatePickedProduct } from '@app/page/online-order/product-picker/logic/calculate-picked-product.ts';
import { useTranslate } from '@tolgee/react';
import MenuSelectionInput from '@app/components/form/input/menu-selection-input.tsx';

const ProductConfigurationSetup = (props: { product: MenuProductItemFragment }) => {
  const { product } = props;
  const { t } = useTranslate();

  const image = product.images.length > 0 ? product.images[0] : null;
  const configurations = getFragmentData(MenuProductConfigurationFragment, product.configurations);

  const addToCart = useProductPicker((state) => state.add);
  const close = useClose();

  const [configurationValues, setConfigurationValues] = useState<string[]>([]);
  const [count, setCount] = useState<number>(1);

  useEffect(() => {
    const configuration = getFragmentData(MenuProductConfigurationFragment, product.configurations[0]);
    const value = getFragmentData(MenuProductConfigurationValueFragment, configuration.values[0]);
    setConfigurationValues([value.id]);
  }, [product.configurations]);

  const validate = () => {
    for (const config of product.configurations) {
      const configuration = getFragmentData(MenuProductConfigurationFragment, config);
      const values = getFragmentData(MenuProductConfigurationValueFragment, configuration.values);
      const idValues = values.map((value) => value.id);

      const requiredAtLeastOne =
        configuration.type === ProductConfigurationType.Base ||
        configuration.type === ProductConfigurationType.Selection;

      if (requiredAtLeastOne && configurationValues.filter((id) => idValues.includes(id)).length !== 1) {
        return false;
      }
    }

    return true;
  };

  const total = calculatePickedProduct(configurationValues, product, count);

  const addToCartHandler = () => {
    if (!validate()) {
      return;
    }

    addToCart({
      productID: product.id,
      quantity: count,
      configurations: configurationValues
        .map((valueID) => {
          const configuration = configurations.find((config) =>
            getFragmentData(MenuProductConfigurationValueFragment, config.values).some((value) => value.id === valueID)
          );

          if (!configuration) {
            return undefined;
          }

          return {
            configurationID: configuration.id,
            valueID: valueID,
          };
        })
        .filter((v) => v !== undefined) as { configurationID: string; valueID: string }[],
    });

    close();
  };

  return (
    <>
      <DialogTitle>
        <div className="flex space-x-4 rounded-xl border p-2">
          {image && <img src={CDNLink(image)} className="h-20 w-20 rounded-md object-cover" alt="Logo" />}
          <div className="flex flex-col pl-2">
            <div className="flex gap-x-2">
              <p className="text-xl font-medium">
                {product.code}. {product.title}
              </p>
            </div>
            <p className="text-sm italic text-gray-500">{product.description}</p>
          </div>
        </div>
      </DialogTitle>

      <MenuSelectionInput
        title={''}
        data={product.allergics}
        className="mt-4"
        value={{
          code: 'Allergy',
          title: '',
        }}
        build={(item) => {
          const name = !item.title ? item.code : `${item.code} - ${item.title}`;
          return {
            id: item.code,
            name: name,
            suffix: product.allergics.map((item) => item.code).join(','),
          };
        }}
        onChange={() => {
          // Do nothing
        }}
      />

      <Description as="div" className="flex flex-col divide-y p-2">
        {product.configurations.map((config) => {
          const configuration = getFragmentData(MenuProductConfigurationFragment, config);
          const configurationValueIDs = getFragmentData(
            MenuProductConfigurationValueFragment,
            configuration.values
          ).map((value) => value.id);

          const required =
            configuration.type === ProductConfigurationType.Base ||
            configuration.type === ProductConfigurationType.Selection;

          return (
            <div key={configuration.id} className="py-3">
              <p className="pb-2 text-lg font-semibold">
                {configuration.title}{' '}
                {required && (
                  <span className="text-xs font-normal">
                    ({t('online-order.picker-page.product-detail.required', 'Required')})
                  </span>
                )}
              </p>
              <div className="grid grid-cols-2 gap-2">
                {configuration.values.map((value, index) => {
                  const configurationValue = getFragmentData(MenuProductConfigurationValueFragment, value);

                  switch (configuration.type) {
                    case ProductConfigurationType.Base:
                      return (
                        <div key={configurationValue.id} className="flex items-center space-x-2">
                          <input
                            type="radio"
                            id={configurationValue.id}
                            name={configuration.id}
                            defaultChecked={!index}
                            onChange={(e) => {
                              if (e.target.checked) {
                                // Delete other selections
                                const newConfigurationValues = configurationValues.filter(
                                  (v) => !configurationValueIDs.includes(v)
                                );

                                // Push new selection
                                newConfigurationValues.push(configurationValue.id);

                                // Update
                                setConfigurationValues([...newConfigurationValues]);
                              } else {
                                // Delete others configuration values
                                setConfigurationValues(configurationValues.filter((v) => v !== configurationValue.id));
                              }
                            }}
                          />
                          <label className="flex flex-col" htmlFor={configurationValue.id}>
                            <p>{configurationValue.name}</p>
                            {configurationValue.price > 0 && (
                              <CurrencyView className="text-sm text-gray-500" price={configurationValue.price} />
                            )}
                          </label>
                        </div>
                      );

                    case ProductConfigurationType.Selection:
                      return (
                        <div key={configurationValue.id} className="flex items-center space-x-2">
                          <input
                            type="radio"
                            id={configurationValue.id}
                            name={configuration.id}
                            onChange={(e) => {
                              if (e.target.checked) {
                                // Delete other selections
                                const newConfigurationValues = configurationValues.filter(
                                  (v) => !configurationValueIDs.includes(v)
                                );

                                // Push new selection
                                newConfigurationValues.push(configurationValue.id);

                                // Update
                                setConfigurationValues([...newConfigurationValues]);
                              } else {
                                // Delete others configuration values
                                setConfigurationValues(configurationValues.filter((v) => v !== configurationValue.id));
                              }
                            }}
                          />
                          <label className="flex flex-col" htmlFor={configurationValue.id}>
                            <p>{configurationValue.name}</p>
                            {configurationValue.price > 0 && (
                              <div className="flex items-baseline text-sm text-gray-500">
                                <p>+</p>
                                <CurrencyView price={configurationValue.price} />
                              </div>
                            )}
                          </label>
                        </div>
                      );

                    case ProductConfigurationType.Option:
                      return (
                        <div key={configurationValue.id} className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            id={configurationValue.id}
                            name={configuration.id}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setConfigurationValues([...configurationValues, configurationValue.id]);
                              } else {
                                setConfigurationValues(configurationValues.filter((v) => v !== configurationValue.id));
                              }
                            }}
                          />
                          <label className="flex flex-col" htmlFor={configurationValue.id}>
                            <p>{configurationValue.name}</p>
                            {configurationValue.price > 0 && (
                              <div className="flex items-baseline text-sm text-gray-500">
                                <p>+</p>
                                <CurrencyView price={configurationValue.price} />
                              </div>
                            )}
                          </label>
                        </div>
                      );
                  }
                })}
              </div>
            </div>
          );
        })}
      </Description>
      {/*Count*/}
      <div className="flex flex-col items-start space-y-2 border-t px-1.5 py-4">
        <p className="text-md font-semibold">{t('online-order.picker-page.product-detail.count', 'Count')}</p>
        <div className="flex items-center space-x-4">
          <button
            className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => {
              if (count > 1) setCount(count - 1);
            }}
          >
            -
          </button>
          <p>{count}</p>
          <button
            className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => {
              setCount(count + 1);
            }}
          >
            +
          </button>
        </div>
      </div>
      <div className="flex items-center justify-end space-x-4 border-t pt-4">
        <CloseButton className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {t('online-order.picker-page.product-detail.button.close', 'Close')}
        </CloseButton>

        <div className="flex-grow" />

        <CurrencyView price={total} />
        <PrimaryButton onClick={addToCartHandler} disabled={!validate()}>
          {t('online-order.picker-page.product-detail.button.add-to-cart', 'Add to cart')}
        </PrimaryButton>
      </div>
    </>
  );
};

export default ProductConfigurationSetup;
