import {
  BranchItemFragment,
  OnlineOrderAvailabilityItemFragment,
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
  OnlineOrderUserState,
} from '@app/graphql/types/graphql.ts';
import { CartSideBarPreviewMode } from '@app/page/online-order/fill-contact-info/utils/cart-side-bar-preview-mode-enum.ts';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { useCurrency } from '@app/components/price/use-currency.ts';
import { CardSelection, CardSelectionData } from '@app/components/card-selection.tsx';
import { formatCurrency } from '@app/components/price/currency-formatter.ts';
import {
  deliveryDiscountAdditionalText,
  pickupDiscountAdditionalText,
} from '@app/page/online-order/fill-contact-info/utils/pickup-discount-additional-text.ts';
import { useOnlineOrderFillPersonalData } from '@app/page/online-order/fill-contact-info/logic/use-online-order-fill-personal-contact.ts';
import { formatGraphQlError, validationErrors } from '@app/module/error/error.ts';
import { captureException } from '@sentry/react';
import useFormData from '@app/utils/use-form-data.ts';
import AutofillAddressInput from '@app/page/online-order/fill-contact-info/component/autofill-address-input.tsx';
import ContactInputCard from '@app/page/online-order/fill-contact-info/component/contact-input-card.tsx';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import SuperButton from '@app/components/super-button.tsx';
import OrderTime from '@app/page/online-order/fill-contact-info/component/order-time.tsx';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import Spinner from '@app/components/spinner.tsx';

interface FormData {
  streetAddress: string;
  streetAddress2: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;

  guestName: string;
  email: string;
  phone: string;
  message: string;
  expectedTime: string | undefined;
}

export default function FillContactInfoPage(props: {
  branch: BranchItemFragment;
  order: OnlineOrderItemFragment;
  onlineOrderBranchConfiguration: OnlineOrderBranchConfigurationItemFragment;
  availabilityData: OnlineOrderAvailabilityItemFragment;
}) {
  const { t } = useTranslate();
  const currency = useCurrency();

  const deliveryModes: CardSelectionData<OnlineOrderDeliveryMode>[] = useMemo(() => {
    return [
      {
        id: OnlineOrderDeliveryMode.Delivery,
        value: OnlineOrderDeliveryMode.Delivery,
        title: t('online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.title', 'Delivery'),
        description: t(
          'online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.description',
          'We will deliver the order to your address'
        ),
        additionalText: [
          t(
            'online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.addition-text',
            'Minimum order amount for delivery: '
          ) + ` ${formatCurrency(props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery, currency)}`,
          deliveryDiscountAdditionalText(
            props.onlineOrderBranchConfiguration.deliveryDiscountAmount,
            props.onlineOrderBranchConfiguration.deliveryDiscountType,
            currency
          ),
        ],
      },
      {
        id: OnlineOrderDeliveryMode.Pickup,
        value: OnlineOrderDeliveryMode.Pickup,
        title: t('online-order.fill-contact-page.form-input.delivery-mode.card-selection.pickup.title', 'Pickup'),
        description: t(
          'online-order.fill-contact-page.form-input.delivery-mode.card-selection.pickup.description',
          'You will pick up the order at the store'
        ),
        additionalText: [
          pickupDiscountAdditionalText(
            props.onlineOrderBranchConfiguration.pickupDiscountAmount,
            props.onlineOrderBranchConfiguration.pickupDiscountType,
            currency
          ),
        ],
      },
    ];
  }, [t, props.onlineOrderBranchConfiguration, currency]);

  let initialDeliveryMode: CardSelectionData<OnlineOrderDeliveryMode> =
    deliveryModes.length > 0 ? deliveryModes[0] : deliveryModes[1];

  if (props.order.deliveryMode) {
    initialDeliveryMode = deliveryModes.find((mode) => mode.value === props.order.deliveryMode) ?? deliveryModes[0];
  }

  // Hooks
  const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(initialDeliveryMode);

  const handleChangeDeliveryMode = (newDeliveryMode: CardSelectionData<OnlineOrderDeliveryMode>) => {
    setSelectedDeliveryMode(newDeliveryMode);
  };

  const [changeUserState, { loading, error }] = useOnlineOrderFillPersonalData();

  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<FormData>({
    streetAddress: props.order.streetAddress ?? '',
    streetAddress2: props.order.streetAddress2 ?? '',
    addressLocality: props.order.addressLocality ?? '',
    addressRegion: props.order.addressRegion ?? '',
    postalCode: props.order.postalCode ?? '',
    addressCountry: props.order.addressCountry ?? '',

    guestName: props.order.guestName ?? '',
    email: props.order.email ?? '',
    phone: props.order.phone ?? '',
    message: props.order.message ?? '',
    expectedTime: props.order.expectedTime ?? '',
  });

  const [emptyErrors, setEmptyErrors] = useState<Record<string, string>>({});
  const [scheduleMode, setScheduleMode] = useState<'order_for_later' | 'now'>();

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const availableDeliveryModes: CardSelectionData<OnlineOrderDeliveryMode>[] = [];

  if (props.onlineOrderBranchConfiguration.deliveryEnable) {
    availableDeliveryModes.push(deliveryModes[0]);
  }

  if (props.onlineOrderBranchConfiguration.pickupEnable) {
    availableDeliveryModes.push(deliveryModes[1]);
  }

  useEffect(() => {
    let updateDeliveryMode: CardSelectionData<OnlineOrderDeliveryMode> = deliveryModes[0];

    if (!props.availabilityData.delivery.now) {
      updateDeliveryMode = deliveryModes[1];
    }

    if (props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery > (props.order.basePrice ?? 0)) {
      updateDeliveryMode = deliveryModes[1];
    }

    setSelectedDeliveryMode(updateDeliveryMode);
  }, [
    props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery,
    props.order.basePrice,
    deliveryModes,
    props.availabilityData.delivery.now,
  ]);

  const validateEmpty = () => {
    let flag = true;
    const errors: Record<string, string> = {};
    if (form.guestName == '') {
      flag = false;
      errors.guestName = t('online-order.information.empty.guestName', 'Guest name should not be empty');
    }
    if (form.email == '') {
      flag = false;
      errors.email = t('online-order.information.empty.email', 'Email should not be empty');
    }
    if (form.phone == '') {
      flag = false;
      errors.phone = t('online-order.information.empty.phone', 'Phone name should not be empty');
    }
    if (form.streetAddress == '' && selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      flag = false;
      errors.streetAddress = t('online-order.information.empty.streetAddress', 'Street address should not be empty');
    }
    if (form.addressLocality == '' && selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      flag = false;
      errors.addressLocality = emptyErrors.streetAddress = t(
        'online-order.information.empty.addressLocality',
        'City should not be empty'
      );
    }
    if (form.postalCode == '' && selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      flag = false;
      errors.postalCode = t('online-order.information.empty.postalCode', 'Postal code should not be empty');
    }

    setEmptyErrors(errors);
    return flag;
  };

  const saveAddressAndChangeState = (nextUserState: OnlineOrderUserState) => {
    if (!validateEmpty()) {
      return;
    }
    changeUserState({
      variables: {
        onlineOrderID: props.order.id,
        input: {
          deliveryMode: selectedDeliveryMode.value,

          streetAddress: form.streetAddress,
          streetAddress2: form.streetAddress2,
          addressLocality: form.addressLocality,
          addressRegion: form.addressRegion,
          postalCode: form.postalCode,
          addressCountry: form.addressCountry,

          guestName: form.guestName,
          email: form.email,
          phone: form.phone,
          message: form.message,

          nextUserState: nextUserState,

          ...(form.expectedTime && { expectedTime: form.expectedTime }),
        },
      },
    }).catch(captureException);
  };

  const backToEditCartStateHandler = () => {
    changeUserState({
      variables: {
        onlineOrderID: props.order.id,
        input: {
          deliveryMode: selectedDeliveryMode.value,

          streetAddress: props.order.streetAddress ?? '',
          streetAddress2: props.order.streetAddress2 ?? '',
          addressLocality: props.order.addressLocality ?? '',
          addressRegion: props.order.addressRegion ?? '',
          postalCode: props.order.postalCode ?? '',
          addressCountry: props.order.addressCountry ?? '',

          guestName: props.order.guestName ?? '',
          email: props.order.email ?? '',
          phone: props.order.phone ?? '',
          message: props.order.message,

          nextUserState: OnlineOrderUserState.PickingProduct,
        },
      },
    }).catch(captureException);
  };

  const reviewDisable = () => {
    if (loading) {
      return true;
    }

    if (selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      if (props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery > (props.order.basePrice ?? 0)) {
        return true;
      }
    }

    return scheduleMode == 'order_for_later' && !form.expectedTime;
  };

  const reviewHandler = () => {
    saveAddressAndChangeState(OnlineOrderUserState.Review);
  };

  return (
    <div className="flex items-center justify-center bg-gray-100 sm:p-8">
      <div className="max-w-[1000px] grow space-y-4 sm:basis-2/3">
        <ApplicationErrorView error={error} />
        <BackStateCard
          loading={loading}
          backToEditCartStateHandler={backToEditCartStateHandler}
          t={(key: string, defaultValue: string) => {
            return t(key, defaultValue);
          }}
        />
        <CartSideBarPreview
          order={props.order}
          onlineOrderBranchConfiguration={props.onlineOrderBranchConfiguration}
          deliveryMode={selectedDeliveryMode.value}
          mode={CartSideBarPreviewMode.OnlyProducts}
        />
        <CardSelection
          title={t('online-order.fill-contact-page.form-input.delivery-mode.label', 'Delivery mode')}
          data={availableDeliveryModes}
          value={selectedDeliveryMode}
          disableDeliveryMode={
            props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery > (props.order.basePrice ?? 0)
          }
          // onChange={setSelectedDeliveryMode}
          onChange={handleChangeDeliveryMode}
        />

        <OrderTime
          availabilityData={props.availabilityData}
          branch={props.branch}
          setParentSceduleMode={(mode: 'order_for_later' | 'now') => {
            setScheduleMode(mode);
          }}
          onlineOrderBranchConfiguration={props.onlineOrderBranchConfiguration}
          deliveryMode={selectedDeliveryMode.value}
          expectedTime={form.expectedTime}
          handleExpectedTime={(expectedTime: string | undefined) => {
            manualHandle('expectedTime', expectedTime);
          }}
          timezone={props.branch.company.settings.timezone}
        />
        {selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery && (
          <div>
            <AutofillAddressInput
              values={{ ...form }}
              onChange={handle}
              streetAddress={(value) => {
                manualHandle('streetAddress', value);
              }}
              error={{ ...validationError, ...emptyErrors }}
            />
          </div>
        )}
        <ContactInputCard
          values={{ ...form }}
          onChange={handle}
          manualHandle={(name: string, value: unknown) => {
            // @ts-expect-error accept for every type
            manualHandle(name as keyof FormData, value);
          }}
          errors={{ ...validationError, ...emptyErrors }}
        />
        <div className="px-1 sm:px-0">
          <SuperButton disabled={reviewDisable()} onClick={reviewHandler}>
            {loading ? <Spinner /> : <span>Next</span>}
          </SuperButton>
        </div>
      </div>
    </div>
  );
}

const BackStateCard = (props: {
  backToEditCartStateHandler: () => void;
  t: (key: string, defaultValue: string) => string;
  loading: boolean;
}) => {
  return (
    <Card className="sticky top-0 z-10 shadow-md sm:relative sm:shadow-none">
      <CardHeader
        title={!props.loading ? props.t('online-order.review-page.back-to-edit-address', 'Back to edit address') : ''}
        handleBackButtonClick={props.backToEditCartStateHandler}
        withBackButton={true}
      >
        {props.loading && <Spinner />}
      </CardHeader>
    </Card>
  );
};
