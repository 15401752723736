import {
  DaysOfWeek,
  MenuProductItemFragment,
  MenuSectionItemFragment,
  ProductConfigurationType,
} from '@app/graphql/types/graphql.ts';
import { naturalCompare } from '@app/utils/natural-compare.ts';
import Card from '@app/components/card/card.tsx';
import CardTableContent from '@app/components/card/card-content-table.tsx';
import ListLayout from '@app/components/list/list-layout.tsx';
import moment from 'moment-timezone';
import classNames from 'classnames';
import ListItem from '@app/components/list/list-item.tsx';
import ProductAddButton from '@app/page/online-order/product-picker/component/product-add-button.tsx';
import ProductConfigurationSetup from '@app/page/online-order/product-picker/component/product-configuration-setup.tsx';
import ProductLabelIcon from '@app/page/online-order/product-picker/component/product-label-icon.tsx';
import CurrencyView from '@app/components/price/currency-view.tsx';
import DialogButton from '@app/components/dialog-button.tsx';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import { useTranslate } from '@tolgee/react';
import { analyticProductDetailClick } from '@app/page/online-order/product-picker/utils/online-order-analytic.ts';
import HoverText from '@app/page/online-order/product-picker/component/hover-text.tsx';
import { CloseButton } from '@headlessui/react';

const dayList = [
  DaysOfWeek.Sunday,
  DaysOfWeek.Monday,
  DaysOfWeek.Tuesday,
  DaysOfWeek.Wednesday,
  DaysOfWeek.Thursday,
  DaysOfWeek.Friday,
  DaysOfWeek.Saturday,
];

export default function ProductPickerList(props: {
  sections: readonly MenuSectionItemFragment[];
  products: readonly MenuProductItemFragment[];
  timezone: string;
}) {
  const sections = [...props.sections].sort((a, b) => naturalCompare(a.code, b.code));
  const { t } = useTranslate();

  return (
    <Card className="rounded-none sm:rounded-b-lg">
      <CardTableContent>
        {sections.map((section) => {
          const products = props.products
            .filter((product) => product.sectionId === section.id)
            .sort((a, b) => a.title.localeCompare(b.title));

          if (products.length === 0) return null;

          const checkSectionEnable = section.enabled;

          let checkSectionRangeTime = true;
          if (
            section.timeRule.__typename == 'MenuSectionRangeTimeRule' &&
            typeof section.timeRule.isAllDays == 'boolean' &&
            section.timeRule.start &&
            section.timeRule.end &&
            section.timeRule.daysOfWeek
          ) {
            const now = moment().tz(props.timezone);
            const startTime = moment(now)
              .set('hours', parseInt(section.timeRule.start.slice(0, 2)))
              .set('minutes', parseInt(section.timeRule.start.slice(3)));
            const endTime = moment(now)
              .set('hours', parseInt(section.timeRule.end.slice(0, 2)))
              .set('minutes', parseInt(section.timeRule.end.slice(3)));

            let checkRangeTimeHour = true;
            if (section.timeRule.isAllDays) {
              checkRangeTimeHour = true;
            } else {
              checkRangeTimeHour = moment().isBetween(startTime, endTime);
            }
            const checkRangeTimeDay = section.timeRule.daysOfWeek.includes(dayList[moment().tz('Europe/Berlin').day()]);

            checkSectionRangeTime = checkRangeTimeHour && checkRangeTimeDay;
          }

          if (!checkSectionEnable) {
            return null;
          }

          return (
            <ListLayout
              id={section.id}
              className={'bg-indigo-500 text-white'}
              key={section.id}
              title={section.name}
              subtitle={section.description}
              header={
                section.timeRule.__typename == 'MenuSectionRangeTimeRule' && (
                  <div className="flex text-sm font-semibold tracking-widest text-red-600">
                    {'('}
                    {t('online-order.picker-product.section.range-time.description-1', 'Open from {start} to {end}', {
                      start: section.timeRule.start,
                      end: section.timeRule.end,
                    })}

                    {!section.timeRule.isAllDays && (
                      <>
                        {t('online-order.picker-product.section.range-time.description-2', ' on {days}', {
                          days: section.timeRule.daysOfWeek
                            ?.map((day) => day.slice(0, 3))
                            .join(', ')
                            .toLowerCase(),
                        })}
                      </>
                    )}
                    {')'}
                  </div>
                )
              }
            >
              {products
                .sort((a, b) => naturalCompare(a.code, b.code))
                .map((product) => {
                  const configurations = product.configurations.map((config) => {
                    const configuration = getFragmentData(MenuProductConfigurationFragment, config);

                    return {
                      ...configuration,
                      values: configuration.values.map((value) => {
                        return getFragmentData(MenuProductConfigurationValueFragment, value);
                      }),
                    };
                  });

                  const singlePrice = configurations.length === 1 && configurations[0].values.length === 1;
                  const basePrices =
                    configurations
                      .find((config) => config.type === ProductConfigurationType.Base)
                      ?.values.map((value) => value.price) ?? [];

                  const minBasePrice = Math.min(...basePrices);
                  return (
                    <div key={product.id} className={classNames(!checkSectionRangeTime ? 'relative opacity-45' : '')}>
                      <ListItem sideContent={<ProductAddButton product={product} />} sideContentAlign={'bottom'}>
                        <DialogButton
                          onClick={() => {
                            analyticProductDetailClick({
                              productName: product.title,
                            });
                          }}
                          content={<ProductConfigurationSetup product={product} />}
                        >
                          <div className="overflow-visible">
                            <div className="flex w-full flex-col space-y-1.5">
                              <p className="flex items-center gap-1 text-base font-semibold text-gray-800">
                                <span>
                                  {product.code}. {product.title}{' '}
                                </span>
                                <div className="group relative">
                                  <HoverText
                                    text={
                                      <span className="text-sm font-medium italic text-indigo-500 hover:border-b-2 hover:border-b-indigo-400">
                                        {product.allergics.map((item) => item.code).join(', ')}
                                      </span>
                                    }
                                    modalContent={
                                      <div>
                                        <p className="text-lg font-semibold">
                                          {t('allergy.header', 'Product allergy')}
                                        </p>
                                        <div className="mt-4 rounded-md border">
                                          {product.allergics.map((item) => (
                                            <div
                                              className="border-b-1 flex items-center gap-x-3 border-b p-3"
                                              key={item.code}
                                            >
                                              <span className="text-sm font-semibold italic text-indigo-500">
                                                {item.code}
                                              </span>
                                              <span className="text-sm font-light italic">{item.title}</span>
                                            </div>
                                          ))}
                                        </div>
                                        <div className="mt-4 flex items-center justify-center">
                                          <CloseButton className="rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold hover:bg-gray-50">
                                            {t('allergy.close-button', 'Cancel')}
                                          </CloseButton>
                                        </div>
                                      </div>
                                    }
                                    hoverContent={
                                      <div>
                                        {product.allergics.map((item) => (
                                          <div
                                            className="border-b-1 flex items-center gap-x-3 border-b p-2"
                                            key={item.code}
                                          >
                                            <span className="text-sm font-semibold italic text-indigo-500">
                                              {item.code}
                                            </span>
                                            <span className="text-sm font-light italic">{item.title}</span>
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  />
                                </div>
                              </p>
                              <p className="line-clamp-3 text-xs text-gray-400">{product.description}</p>

                              <div className="flex">
                                {product.labels?.sort().map((label) => {
                                  return (
                                    <div key={label} className="h-5 w-5">
                                      <ProductLabelIcon key={label} label={label} />
                                    </div>
                                  );
                                })}
                              </div>

                              <div className="flex w-full items-center justify-between space-x-2 text-base font-medium text-gray-900">
                                {singlePrice ? (
                                  <CurrencyView price={minBasePrice} />
                                ) : (
                                  <div>
                                    {t('online-order.picker-page.product-list-style.start-from', 'Start from')}{' '}
                                    <CurrencyView price={minBasePrice} />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="flex-grow" />
                          </div>
                        </DialogButton>
                      </ListItem>
                      {!checkSectionRangeTime && <div className="absolute inset-0 z-10"></div>}
                    </div>
                  );
                })}
            </ListLayout>
          );
        })}
      </CardTableContent>
    </Card>
  );
}
