import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardTableContent from '@app/components/card/card-content-table.tsx';
import { Radio, RadioGroup } from '@headlessui/react';
import ListItem from '@app/components/list/list-item.tsx';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useOnlineOrderOpen from '@app/page/online-order/fill-contact-info/logic/use-online-order-open.ts';
import {
  BranchItemFragment,
  OnlineOrderAvailabilityItemFragment,
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderDeliveryMode,
  OpeningTimeServiceType,
} from '@app/graphql/types/graphql.ts';
import { todayIs } from '@app/page/online-order/product-picker/utils/today.ts';
import OnlineOrderDateInput from '@app/page/online-order/fill-contact-info/component/online-order-date-input.tsx';

enum ScheduleTimeMode {
  NOW,
  ORDER_FOR_LATER,
}

interface ScheduleTimeModeSelection {
  id: 'order_for_later' | 'now';
  mode: ScheduleTimeMode;
  titleKey: string;
  titleDefaultValue: string;
}

const scheduleTimeModeSelections: ScheduleTimeModeSelection[] = [
  {
    id: 'now',
    mode: ScheduleTimeMode.NOW,
    titleKey: 'online-order.fill-contact-info.order-time.now.header',
    titleDefaultValue: 'Now',
  },
  {
    id: 'order_for_later',
    mode: ScheduleTimeMode.ORDER_FOR_LATER,
    titleKey: 'online-order.fill-contact-info.order-time.order-for-later.header',
    titleDefaultValue: 'Order for later',
  },
];

export default function OrderTime(props: {
  branch: BranchItemFragment;
  deliveryMode: OnlineOrderDeliveryMode;
  expectedTime: string | undefined;
  setParentSceduleMode: (mode: 'order_for_later' | 'now') => void;
  handleExpectedTime: (expectedTime: string | undefined) => void;
  timezone: string;
  availabilityData: OnlineOrderAvailabilityItemFragment;
  onlineOrderBranchConfiguration: OnlineOrderBranchConfigurationItemFragment;
}) {
  const { t } = useTranslate();
  const { data: openTimeData } = useOnlineOrderOpen(props.branch.id);
  const today = todayIs();

  const [scheduleTimeList, setScheduleTimeList] = useState<ScheduleTimeModeSelection[]>(scheduleTimeModeSelections);
  const [scheduleTimeMode, setScheduleTimeMode] = useState<ScheduleTimeModeSelection>(scheduleTimeModeSelections[1]);

  useEffect(() => {
    const isOutOfTime =
      (props.deliveryMode == OnlineOrderDeliveryMode.Pickup &&
        !openTimeData?.storefront_isOnlineOrderOpen.isPickupAvailable) ||
      (props.deliveryMode == OnlineOrderDeliveryMode.Delivery &&
        !openTimeData?.storefront_isOnlineOrderOpen.isDeliveryAvailable);

    const updateScheduleTimeList: ScheduleTimeModeSelection[] = isOutOfTime
      ? [scheduleTimeModeSelections[1]]
      : scheduleTimeModeSelections;

    const currentTimeMode = !isOutOfTime && props.expectedTime ? updateScheduleTimeList[1] : updateScheduleTimeList[0];

    setScheduleTimeList(updateScheduleTimeList);
    setScheduleTimeMode(currentTimeMode);
  }, [props.deliveryMode, openTimeData, props.expectedTime]);

  useEffect(() => {
    props.setParentSceduleMode(scheduleTimeMode.id);
  }, [props, scheduleTimeMode]);

  const pickup = props.branch.openingTimes?.filter(
    (item) => item.dayOfWeek == today && item.service == OpeningTimeServiceType.OnlineOrderPickup
  );

  const delivery = props.branch.openingTimes?.filter(
    (item) => item.dayOfWeek == today && item.service == OpeningTimeServiceType.OnlineOrderDelivery
  );

  const onSelectMode = (value: ScheduleTimeModeSelection) => {
    if (value.mode == ScheduleTimeMode.NOW) {
      props.handleExpectedTime(undefined);
    }
    setScheduleTimeMode(value);
  };

  const onSelectTimeBlock = (value: string) => {
    props.handleExpectedTime(value);
  };

  return (
    <Card>
      <CardHeader title={t('online-order.fill-contact-info.order-time.header', 'Order Time')} />

      <CardTableContent>
        <RadioGroup value={scheduleTimeMode} onChange={onSelectMode}>
          {scheduleTimeList.map((item) => {
            return (
              <Radio key={item.id} value={item} aria-label={t(item.titleKey, item.titleDefaultValue)}>
                {({ checked }) => {
                  return (
                    <ListItem data-checked>
                      <div className="flex items-center gap-4">
                        <div className="flex items-center">
                          <CheckCircleIcon
                            className={classNames(!checked ? 'hidden' : '', 'h-5 w-5 text-gray-700')}
                            aria-hidden="true"
                          />

                          <div
                            className={classNames(
                              !checked ? '' : 'hidden',
                              'h-4 w-4 rounded-full border border-2 border-gray-800 hover:border-gray-400'
                            )}
                          ></div>
                        </div>

                        <div className="flex flex-col">
                          <p className="block text-sm font-medium text-gray-900">{item.titleDefaultValue}</p>
                          {item.mode == ScheduleTimeMode.ORDER_FOR_LATER && (
                            <p className="text-sm text-gray-500">
                              {pickup && pickup.length > 0 && props.deliveryMode == OnlineOrderDeliveryMode.Pickup && (
                                <div className="flex items-center gap-x-1">
                                  <span>{t('online-order.business-card.pickup', 'Today pickup')}:</span>
                                  {pickup.map((item, index) => (
                                    <p key={index}>
                                      {item.openTime} - {item.closeTime}
                                      {index < pickup.length - 1 && <span>,</span>}
                                    </p>
                                  ))}
                                </div>
                              )}
                              {delivery &&
                                delivery.length > 0 &&
                                props.deliveryMode == OnlineOrderDeliveryMode.Delivery && (
                                  <div className="flex items-center gap-x-1">
                                    <span>{t('online-order.business-card.delivery', 'Today delivery')}:</span>
                                    {delivery.map((item, index) => (
                                      <p key={index}>
                                        {item.openTime} - {item.closeTime}
                                        {index < delivery.length - 1 && <span>,</span>}
                                      </p>
                                    ))}
                                  </div>
                                )}
                            </p>
                          )}
                        </div>
                      </div>
                    </ListItem>
                  );
                }}
              </Radio>
            );
          })}
        </RadioGroup>

        {scheduleTimeMode.mode == ScheduleTimeMode.ORDER_FOR_LATER && (
          <OnlineOrderDateInput
            branch={props.branch}
            availabilityData={props.availabilityData}
            currentValue={props.expectedTime}
            handleFunction={onSelectTimeBlock}
            type={
              props.deliveryMode == OnlineOrderDeliveryMode.Delivery
                ? OpeningTimeServiceType.OnlineOrderDelivery
                : OpeningTimeServiceType.OnlineOrderPickup
            }
          />
        )}
      </CardTableContent>
    </Card>
  );
}
